import React, { useState } from "react";
import createRecord from "../../utils/createRecord";
import { useNavigate } from "react-router-dom";

const SignUpForm = ({ setIsSuccessMessage }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSuccessMessage(true);
      window.location.href =
        "https://apps.apple.com/app/drogo-charts-ai/id6738845580";
    } catch (error) {
      setIsSuccessMessage(false);
      console.log("error");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <button type="submit" className="btn sign_up">
        Download Now
      </button>
    </form>
  );
};

export default SignUpForm;
