import React from "react";

function Termsdtls() {
  return (
    <>
      <section className="blog_detail_section">
        <div className="container container-sm">
          <div className="blog_head">
            <ul className="blog_info">
              <li>Dec 19, 2024</li>
            </ul>
            <h1>Terms Of Use.</h1>
          </div>
          <div className="blog_body">
            <h2>Introduction</h2>
            <p>
              Welcome to Drogo, an AI-powered mobile application designed to
              help users discover the best stocks and cryptocurrencies through
              personalized recommendations. By accessing or using Drogo, you
              acknowledge and agree to these Terms of Use, which govern your use
              of our app and services. If you do not agree with these terms,
              please discontinue using the app immediately. These terms form a
              binding agreement between you and Drogo.
            </p>

            <h2>Acceptance of Terms</h2>
            <p>
              By using the Drogo app, you agree to comply with these Terms of
              Use as well as our Privacy Policy, which is incorporated into
              these terms by reference. These terms apply to all users of the
              app, including visitors, subscribers, and creators. By using the
              app, you confirm that you have read, understood, and accepted
              these terms in their entirety.
            </p>
            <h2>Eligibility</h2>
            <p>
              Drogo is available to individuals who are at least 18 years old
              and legally capable of entering into binding agreements. By using
              the app, you represent and warrant that you meet these eligibility
              criteria. If you are using Drogo on behalf of an organization or
              company, you represent that you are authorized to bind that entity
              to these Terms of Use.
            </p>

            <h2>Account Registration</h2>
            <p>
              To access certain features of Drogo, you must create an account
              using a valid email address or supported third-party services such
              as Google or Facebook. During the registration process, you agree
              to provide accurate, current, and complete information. You are
              responsible for maintaining the security of your account,
              including safeguarding your password. You must notify us
              immediately if you suspect any unauthorized access to your
              account. Drogo reserves the right to suspend or terminate accounts
              that provide false information or violate these terms.
            </p>

            <h2>Use of the App</h2>
            <p>
              You agree to use Drogo solely for lawful purposes and in
              compliance with these Terms of Use. You must not engage in any
              activity that disrupts, negatively affects, or interferes with
              other users' experience of the app. This includes, but is not
              limited to, using automated systems such as bots, impersonating
              others, misrepresenting your identity, or engaging in any activity
              that violates applicable laws and regulations. Drogo reserves the
              right to investigate and take appropriate action, including
              suspension or termination, against anyone who violates these
              provisions.
            </p>

            <h2>Content and Subscriptions</h2>
            <p>
              Drogo enables users to discover stocks and cryptocurrencies
              through curated content, including paid subscriptions to channels.
              You retain ownership of any content you upload, but by doing so,
              you grant Drogo a non-exclusive, royalty-free, worldwide license
              to use, reproduce, and display your content to operate the app
              effectively. Paid subscriptions are processed through third-party
              payment providers, and Drogo is not liable for the accuracy or
              reliability of content provided by creators.
            </p>

            <h2>Communication</h2>
            <p>
              Drogo provides users with the ability to communicate through chat
              features and other interaction tools. These features are intended
              for informational and collaborative purposes only. Drogo does not
              verify or endorse the content of communications between users and
              creators. You are solely responsible for the content you share and
              the interactions you have within the app.
            </p>

            <h2>Notifications</h2>
            <p>
              Drogo may send you notifications via push alerts, emails, or SMS
              messages to keep you informed about account activities, updates,
              and other relevant information. You can manage your notification
              preferences directly within the app's settings.
            </p>

            <h2>No Financial Advice Disclaimer</h2>
            <p>
              The content, data, charts, analyses, and other information
              provided by our App ("Content") are for informational purposes
              only. Drogo is not a licensed financial advisor and does not offer
              personalized investment advice, recommendations, or endorsements
              of any financial instruments or cryptocurrencies.The Content is
              not intended to be and should not be relied upon as financial,
              legal, or tax advice. You should seek professional advice from a
              licensed financial advisor or other qualified professionals before
              making any investment decisions.
            </p>

            <h2>No Fiduciary Relationship</h2>
            <p>
              Your use of our App does not create any fiduciary or
              advisor-client relationship between you and Drogo. You acknowledge
              and agree that: Drogo is a research tool and does not provide any
              guarantees or warranties regarding the accuracy, completeness, or
              reliability of the Content. You are solely responsible for your
              own investment decisions and assume all risks associated with
              trading or investing in financial instruments or cryptocurrencies.
            </p>

            <h2>Accuracy of Information</h2>
            <p>
              While we strive to provide accurate and up-to-date information, we
              do not guarantee the accuracy, completeness, or reliability of any
              Content on the App. Drogo is not responsible for any errors or
              omissions, or for the results obtained from the use of such
              information.
            </p>

            <h2>User Responsibility</h2>
            <p>
              You acknowledge that: The Content provided on the App is subject
              to risks and uncertainties inherent in financial markets and
              cryptocurrency trading. You must conduct your own independent
              research and due diligence before making any financial decisions.
            </p>

            <h2>Limitation of Liability</h2>
            <p>
              Under no circumstances shall Drogo, its affiliates, employees, or
              agents be liable for any direct, indirect, incidental,
              consequential, or punitive damages arising out of or related to
              your use of the App or reliance on the Content.
            </p>

            <h2>Subscription Plans and Payments</h2>
            <p>
              Drogo offers both free and paid subscription plans. Detailed
              pricing information is available within the app and on our
              website. Paid subscriptions are automatically renewed unless
              canceled by the user. Payments are processed securely through
              third-party services, and refunds, if applicable, are subject to
              our Refund Policy, which is outlined in the Help Center. Drogo
              reserves the right to modify its subscription plans or pricing at
              any time, with prior notice to users.
            </p>

            <h2>Intellectual Property</h2>
            <p>
              All trademarks, logos, and intellectual property associated with
              Drogo are owned by Drogo or its licensors. You agree not to copy,
              reproduce, distribute, or use any intellectual property from the
              app without prior written permission from Drogo. Unauthorized use
              of Drogo’s intellectual property is strictly prohibited and may
              result in legal action.
            </p>

            <h2>Prohibited Conduct</h2>
            <p>
              Drogo prohibits any behavior that disrupts the community or
              violates the integrity of the platform. This includes spamming,
              advertising unrelated products or services, collecting user data
              without consent, and attempting to sell or transfer your account.
              Users found engaging in prohibited conduct may face account
              suspension or permanent termination.
            </p>

            <h2>Advertising</h2>
            <p>
              Drogo may display advertisements within the app for services or
              products relevant to its users. These advertisements are provided
              by third-party advertisers, and Drogo is not responsible for their
              content, accuracy, or reliability. Users are encouraged to
              exercise discretion when engaging with any advertisements
              displayed on the platform.
            </p>

            <h2>Reviews and Ratings</h2>
            <p>
              Users can leave reviews and ratings for creators or channels based
              on their experiences. Drogo does not control or endorse the
              content of these reviews and is not liable for inaccuracies or
              disputes arising from them. Any concerns about reviews should be
              directed to our support team at support@drogo.live.
            </p>

            <h2>Termination</h2>
            <p>
              Drogo reserves the right to suspend or terminate your account and
              access to the app at its discretion, without prior notice, if it
              believes you have violated these Terms of Use or engaged in
              harmful behavior. Users who wish to terminate their accounts can
              do so by contacting Drogo’s support team.
            </p>

            <h2>Governing Law</h2>
            <p>
              These Terms of Use are governed by the laws of the State of
              Israel. Any disputes arising from or related to these terms shall
              be resolved exclusively in the courts located in Israel.
            </p>

            <h2>Changes to Terms of Use</h2>
            <p>
              Drogo reserves the right to update these Terms of Use at any time.
              Changes will be posted on the app, and your continued use of the
              app after such updates constitutes acceptance of the revised
              terms.
            </p>

            <p>
              By using Drogo, you acknowledge that you have read, understood,
              and agree to be bound by these Terms of Use.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Termsdtls;
