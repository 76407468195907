import React, { useEffect } from "react";
import Header from "../Header/Main";
import PrivacyDetails from "../Menprivacypolicy/Privacydtls";
import Footer from "../Footer/Main";
import Aos from "aos";

function Main() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <Header />
      <div className="page_wrapper">
        <PrivacyDetails />
        <Footer />
      </div>
    </>
  );
}

export default Main;
