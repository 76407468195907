import React from "react";

function Privacydtls() {
  return (
    <>
      <section className="blog_detail_section">
        <div className="container container-sm">
          <div className="blog_head">
            <ul className="blog_info">
              <li>Dec 19, 2024</li>
            </ul>
            <h1>Privacy Policy.</h1>
          </div>
          <div className="blog_body">
            <h2>Introduction</h2>
            <p>
              Drogo ("we," "our," or "us") respects your privacy and is
              committed to protecting the personal information you share with
              us. This Privacy Policy outlines how we collect, use, and
              safeguard your information when you use the Drogo mobile
              application (the "App") and its associated services. By using the
              App, you agree to the terms of this Privacy Policy. We collect
              information that you provide directly to us, information collected
              automatically, and information from third parties. This includes
              registration information such as your name, email address, and
              password; profile details, including preferences, interests, and
              uploaded content; and payment details for subscription services,
              handled securely by third-party payment processors like Apple and
              Google. Automatically collected information includes device
              information such as IP address, operating system, and device
              identifiers; usage data, including interactions with the App and
              features you use; and location data, where enabled, to provide
              location-based features. We also collect data from third parties,
              including social media or third-party accounts you connect to the
              App, and payment confirmations and subscription status from
              payment processors.
            </p>
            <h2>Information We Collect</h2>
            <p>
              We use your information to provide and improve the App’s
              functionality and features, personalize your experience including
              AI-driven recommendations, process payments and manage
              subscriptions, communicate with you regarding updates, promotions,
              and support, and comply with legal obligations and enforce our
              Terms of Use. We do not sell your personal information. However,
              we may share your data with service providers who assist us in
              operating the App, processing payments, or delivering
              communications; business partners for co-branded services or
              features available within the App; legal authorities when required
              to comply with legal processes or to protect our rights; and other
              users with limited profile information visible when engaging with
              features like subscriptions or messaging.
            </p>

            <p>
              You have rights regarding your personal information, including
              access and correction to review and update your personal details
              through the App settings, data portability to request a copy of
              your information in a portable format, deletion to request the
              deletion of your data subject to legal obligations, and marketing
              preferences to opt-out of promotional communications. To exercise
              these rights, contact us at support@drogo.live We retain your
              information for as long as necessary to provide services, comply
              with legal requirements, resolve disputes, and enforce agreements.
              When no longer needed, we securely delete or anonymize your data.
              We implement technical and organizational measures to protect your
              data from unauthorized access, loss, or misuse. While we strive to
              safeguard your information, no system is completely secure.
            </p>

            <p>
              The App is not intended for individuals under 18 years of age. We
              do not knowingly collect data from children. If we learn that a
              child has provided personal information, we will delete it
              promptly. By using the App, you consent to your information being
              processed in the United States and other countries. We ensure
              appropriate safeguards for international data transfers. The App
              may include links to third-party websites or services. We are not
              responsible for their privacy practices. Please review their
              policies before sharing your data. We may update this Privacy
              Policy periodically. Changes will be effective when posted in the
              App, and we will notify you of significant updates.
            </p>

            <p>
              By using the Drogo App, you acknowledge that you have read,
              understood, and agree to this Privacy Policy.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Privacydtls;
