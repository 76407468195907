import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Components/Home/Main";
import Soon from "../Components/Soon/Main";
import Contact from "../Components/Contact/Main";
import PrivacyPolicy from "../Components/PrivacyPolicy/Main";
import TermsOfUse from "../Components/TermsOfUse/Main";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/soon" element={<Soon />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
      </Routes>
    </>
  );
};

export default Routing;
